.header-wrapper {
    position: fixed;
    max-width: 1024px;
    min-height: 60px;
    z-index: 2;
    top: 0;
    width: 100%;
    background-color: #333;
    box-shadow: 0px 6px 12px 0px rgba(0, 0, 0, 0.3);
    .header {
        display: flex;
        justify-content: space-between;
        align-items: center;
        min-height: 60px;
        h1 {
            font-family: "SourceSans-Semibold", sans-serif;
            font-size: 18px;
            text-align: left;
            padding: 0 0 0 21px;
            margin: 0;
            color: #fff;
            letter-spacing: 1px;
            cursor: pointer;
        }
        button {
            border: none;
            background: none;
            -webkit-appearance: none;
            height: 49px;
            transition: transform 0.3s ease;
        }
        button:hover,
        button:focus {
            transform: scale(1.2);
        }
        button:focus {
            outline: none;
        }
        .icon {
            width: 45px;
            height: 45px;
            stroke: #fff;
            stroke-width: 4.5;
            cursor: pointer;
        }
        .hamburger.open {
            .lijn1,
            .lijn4 {
                opacity: 0;
            }
            .lijn2 {
                transform: rotate(45deg);
            }
            .lijn3 {
                transform: rotate(-45deg);
            }
        }
    }

    // Small devices (landscape phones, 576px and up)
    @media (min-width: 576px) {
        .header {
            h1 {
                font-size: 21px;
            }
        }
    }

    // Medium devices (tablets, 768px and up)
    @media (min-width: 768px) {
        .header {
            h1 {
                font-size: 24px;
            }
        }
    }

    // Large devices (desktops, 992px and up)
    @media (min-width: 992px) {
        .header {
            h1 {
                font-size: 27px;
            }
        }
    }
}
