.social {
    margin-top: 9px;
    a {
        height: 45px;
        display: inline-block;
    }
    .icon {
        width: 45px;
        fill: #009FE3;
    }
    .icon:hover {
        transition: all 0.3s ease;
        transform: scale(1.2);
    }
}
