.footer {
	position: relative;
	box-sizing: border-box;
	width: 100%;
	min-height: 60px;
	border-top: 2px solid;
	padding: 6px 21px;
	background-color: #fff;
	overflow: hidden;
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	align-items: center;
	.logo {
		max-width: 300px;
		height: 30px;
	}

	@media (min-width: 576px) {
		flex-direction: row;
		padding: 0px 21px;
	}
}
