.app {
  position: relative;
  max-width: 1024px;
  width: auto;
  margin: 0 auto;
  background-color: #fff;
}
.app.modalOpen {
	overflow: hidden;
}
