@charset "UTF-8";
.Map h2 {
  background-color: #333;
  color: #fff;
  padding: 6px;
  border-radius: 18px;
  text-align: center; }

.Map .cluster {
  border-radius: 12px;
  padding: 20px;
  height: 180px;
  margin: 3px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  cursor: pointer;
  position: relative; }
  .Map .cluster h4 {
    color: #fff;
    text-align: center;
    padding: 0;
    margin: 0;
    font-size: 18px;
    pointer-events: none; }
  .Map .cluster ul {
    padding: 0;
    margin: 0;
    list-style: none;
    pointer-events: none; }
    .Map .cluster ul li {
      background-color: #fff;
      border-radius: 12px;
      padding: 6px 12px;
      margin-top: 6px;
      font-weight: 600;
      font-size: 15px;
      color: #333; }
  .Map .cluster .bullets {
    background-color: #fff;
    margin: 0;
    padding: 6px;
    border: 2px solid #333;
    border-radius: 12px;
    position: relative; }
    .Map .cluster .bullets li {
      position: relative;
      padding: 3px 3px 3px 21px;
      margin: 0;
      font-weight: 400; }
    .Map .cluster .bullets li:before {
      content: " ";
      position: absolute;
      top: 6px;
      left: 3px;
      width: 15px;
      height: 15px;
      background-image: url(../../images/station.svg); }
  .Map .cluster.vrijheid {
    -ms-grid-row: 1;
    -ms-grid-column: 1;
    background-color: #7b9cae; }
    .Map .cluster.vrijheid .bullets {
      border: 2px solid #7b9cae; }
  .Map .cluster.rechtvaardigheid {
    -ms-grid-row: 1;
    -ms-grid-column: 2;
    background-color: #009fe3; }
    .Map .cluster.rechtvaardigheid .bullets {
      border: 2px solid #009fe3; }
  .Map .cluster.puurheid {
    -ms-grid-row: 1;
    -ms-grid-column: 3;
    background-color: #75529d; }
    .Map .cluster.puurheid .bullets {
      border: 2px solid #75529d; }
  .Map .cluster.loyaliteit {
    -ms-grid-row: 2;
    -ms-grid-column: 1;
    background-color: #ee7203; }
    .Map .cluster.loyaliteit .bullets {
      border: 2px solid #ee7203; }
  .Map .cluster.hiërarchie {
    -ms-grid-row: 2;
    -ms-grid-column: 2;
    background-color: #f9b000; }
    .Map .cluster.hiërarchie .bullets {
      border: 2px solid #f9b000; }
  .Map .cluster.empathie {
    -ms-grid-row: 2;
    -ms-grid-column: 3;
    background-color: #e63312; }
    .Map .cluster.empathie .bullets {
      border: 2px solid #e63312; }
  .Map .cluster.continuïteit {
    -ms-grid-row: 3;
    -ms-grid-column: 1;
    background-color: #00a0a5; }
    .Map .cluster.continuïteit .bullets {
      border: 2px solid #00a0a5; }
  .Map .cluster.kwaliteit {
    -ms-grid-row: 3;
    -ms-grid-column: 2;
    background-color: #95c23d; }
    .Map .cluster.kwaliteit .bullets {
      border: 2px solid #95c23d; }
  .Map .cluster.nut {
    -ms-grid-row: 3;
    -ms-grid-column: 3;
    background-color: #2da05f; }
    .Map .cluster.nut .bullets {
      border: 2px solid #2da05f; }

.Map .grid {
  box-sizing: border-box;
  display: grid;
  grid-template-columns: 1fr; }
  .Map .grid .cluster {
    height: 180px; }
    .Map .grid .cluster .button-close-cluster {
      display: none; }
  @media (min-width: 576px) {
    .Map .grid {
      grid-template-columns: 1fr 1fr; } }
  @media (min-width: 768px) {
    .Map .grid {
      display: -ms-grid;
      -ms-grid-columns: 1fr 1fr 1fr;
      grid-template-columns: 1fr 1fr 1fr; } }

.Map .grid-open .cluster {
  height: auto; }
  .Map .grid-open .cluster ul {
    display: none; }

.Map .grid-open .cluster.open {
  cursor: default; }
  .Map .grid-open .cluster.open ul {
    display: block; }
    .Map .grid-open .cluster.open ul li {
      background-color: transparent; }
      .Map .grid-open .cluster.open ul li span {
        background-color: #fff;
        border-radius: 12px 12px 0px 0px;
        padding: 6px 12px 21px 12px;
        line-height: 24px; }
  .Map .grid-open .cluster.open .button-close-cluster {
    display: block;
    position: absolute;
    right: 0;
    top: 0;
    border: none;
    background: none;
    -webkit-appearance: none; }
    .Map .grid-open .cluster.open .button-close-cluster .kruis {
      width: 39px;
      height: 39px;
      stroke: #fff;
      cursor: pointer; }
      .Map .grid-open .cluster.open .button-close-cluster .kruis line {
        transition: all 0.3s ease;
        transform-origin: 50% 50%; }
    .Map .grid-open .cluster.open .button-close-cluster .kruis:hover .lijn1 {
      transform: rotate(45deg); }
    .Map .grid-open .cluster.open .button-close-cluster .kruis:hover .lijn2 {
      transform: rotate(-45deg); }
    .Map .grid-open .cluster.open .button-close-cluster:focus {
      transform: scale(1.4);
      outline: none; }

.Map .grid-vrijheid,
.Map .grid-loyaliteit,
.Map .grid-continuïteit,
.Map .grid-rechtvaardigheid,
.Map .grid-hiërarchie,
.Map .grid-kwaliteit,
.Map .grid-puurheid,
.Map .grid-empathie,
.Map .grid-nut {
  grid-template-columns: 1fr; }

@media (min-width: 576px) {
  .Map .grid-vrijheid,
  .Map .grid-loyaliteit,
  .Map .grid-continuïteit {
    -ms-grid-columns: 4fr 1fr 1fr;
    grid-template-columns: 4fr 1fr 1fr; }
  .Map .grid-rechtvaardigheid,
  .Map .grid-hiërarchie,
  .Map .grid-kwaliteit {
    -ms-grid-columns: 1fr 4fr 1fr;
    grid-template-columns: 1fr 4fr 1fr; }
  .Map .grid-puurheid,
  .Map .grid-empathie,
  .Map .grid-nut {
    -ms-grid-columns: 1fr 1fr 4fr;
    grid-template-columns: 1fr 1fr 4fr; } }
